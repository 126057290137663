import {
  ACTIVATION_EXPORT_API,
  BOOKING_ADD_API,
  BOOKING_DETAIL_FETCH_API,
  BOOKING_EXPORT_API,
  BOOKING_FETCH_API,
  VBOOKING_ADD_API,
  VBOOKING_DETAIL_FETCH_API,
  VBOOKING_FETCH_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as BOOKING_ACTIONS from "./actions";

export const fetchBookingsLists = (formData, type = "test-drive") => {
  const api = new APICore();

  const getApi = () => {
    if (type === "test-drive") {
      return api.storeAPI(BOOKING_FETCH_API, formData);
    } else {
      return api.storeAPI(VBOOKING_FETCH_API, formData);
    }
  };

  return (dispatch) => {
    dispatch(BOOKING_ACTIONS.bookingsFetchRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            BOOKING_ACTIONS.bookingsFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          BOOKING_ACTIONS.bookingsFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchBookingDetailByIdentifier = (id, type = "test-drive") => {
  const api = new APICore();

  const getApi = () => {
    if (type === "test-drive") {
      return api.fetchAPI(`${BOOKING_DETAIL_FETCH_API}/${id}`);
    } else {
      return api.fetchAPI(`${VBOOKING_DETAIL_FETCH_API}/${id}`);
    }
  };

  return (dispatch) => {
    dispatch(BOOKING_ACTIONS.bookingsDetailFetchRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          if (type === "test-drive") {
            dispatch(
              BOOKING_ACTIONS.bookingsDetailFetchRequestSuccess(
                response?.data?.data
              )
            );
          } else {
            let newResponse = { ...response?.data?.data };
            if (response?.data?.data?.responses) {
              newResponse.responses = (
                response?.data?.data?.responses
              );
            }
            dispatch(
              BOOKING_ACTIONS.bookingsDetailFetchRequestSuccess(newResponse)
            );
            return newResponse;
          }

          return response;
        }
      })
      .catch((error) => {
        dispatch(
          BOOKING_ACTIONS.bookingsDetailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertBooking = (
  formData,
  action = "add",
  type = "test-drive"
) => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "add": {
        if (type === "test-drive") {
          return api.storeAPI(`${BOOKING_ADD_API}`, formData);
        } else {
          return api.storeAPI(`${VBOOKING_ADD_API}`, formData);
        }
      }

      case "update": {
        if (type === "test-drive") {
          return api.storeAPI(`${BOOKING_ADD_API}?_method=PUT`, formData);
        } else {
          return api.storeAPI(`${VBOOKING_ADD_API}?_method=PUT`, formData);
        }
      }

      case "delete": {
        if (type === "test-drive") {
          return api.deleteAPI(`${BOOKING_ADD_API}/${formData}`);
        } else {
          return api.deleteAPI(`${VBOOKING_ADD_API}/${formData}`);
        }
      }

      default:
        return;
    }
  };

  return (dispatch) => {
    dispatch(BOOKING_ACTIONS.bookingUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            BOOKING_ACTIONS.bookingUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          BOOKING_ACTIONS.bookingUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const exportBookingData = (formData, type = null) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(BOOKING_ACTIONS.exportBookingDataRequest());


    let url;

    switch (type) {
      case 'exchange':
        url = `${BOOKING_EXPORT_API}/estimation`;
        break;
      case "activation":
        url = `${ACTIVATION_EXPORT_API}`;
        break;

      default:
        url = BOOKING_EXPORT_API
        break;

    }

    return api
      .storeAPI(url, formData, "blob")
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            BOOKING_ACTIONS.exportBookingDataRequestSuccess(response?.data)
          );
          return response;
        } else {
          dispatch(
            BOOKING_ACTIONS.exportBookingDataRequestFailure("SERVER ERROR")
          );
        }
      })
      .catch((error) => {
        dispatch(
          BOOKING_ACTIONS.exportBookingDataRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
