import {
  RESPONSE_ADD_API,
  RESPONSE_DETAIL_FETCH_API,
  RESPONSE_DROPDOWN_API,
  RESPONSE_FETCH_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as RESPONSE_ACTIONS from "./actions";

export const fetchResponseList = (formData) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(RESPONSE_ACTIONS.responsesFetchRequest());

    return api
      .storeAPI(RESPONSE_FETCH_API, formData)
      .then((response) => {
        if (response?.status === 200) {
          let newResponse = { ...response?.data?.data };
          if (response?.data?.data) {
            const newData = response?.data?.data?.map((item) => ({
              ...item,
              responses: (item.responses),
            }));

            newResponse.data = newData;

            dispatch(
              RESPONSE_ACTIONS.responsesFetchRequestSuccess(newResponse)
            );
            return newResponse;
          }
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          RESPONSE_ACTIONS.responsesFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchResponseDetailByIdentifier = (id) => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(RESPONSE_ACTIONS.responsesDetailFetchRequest());

    return api
      .fetchAPI(`${RESPONSE_DETAIL_FETCH_API}/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          let newResponse = [];

          if (response?.data?.data) {
            newResponse = { ...response?.data?.data };
            newResponse.responses = (response?.data?.data?.responses);
            dispatch(
              RESPONSE_ACTIONS.responsesDetailFetchRequestSuccess(newResponse)
            );

            return newResponse;
          }

          return response;
        }
      })
      .catch((error) => {
        dispatch(
          RESPONSE_ACTIONS.responsesDetailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertResponse = (formData, action = "add") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "add":
        return api.storeAPI(`${RESPONSE_ADD_API}`, formData);

      case "update":
        return api.storeAPI(`${RESPONSE_ADD_API}?_method=PUT`, formData);

      case "delete":
        return api.deleteAPI(`${RESPONSE_ADD_API}/${formData}`);

      default:
        return api.storeAPI(`${RESPONSE_ADD_API}`, formData);
    }
  };

  return (dispatch) => {
    dispatch(RESPONSE_ACTIONS.responseUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            RESPONSE_ACTIONS.responseUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          RESPONSE_ACTIONS.responseUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchResponseDropdown = () => {
  const api = new APICore();

  return (dispatch) => {
    dispatch(RESPONSE_ACTIONS.responseDropdownRequest());

    return api
      .fetchAPI(RESPONSE_DROPDOWN_API)
      .then((response) => {
        if (response?.status === 200) {
          let newResponse = [];
          if (response.data.data) {
            newResponse = response.data.data.map((item) => JSON.parse(item));

            dispatch(
              RESPONSE_ACTIONS.responseDropdownRequestSuccess(newResponse)
            );

            return newResponse;
          }

          return response;
        } else {
          dispatch(
            RESPONSE_ACTIONS.responseDropdownRequestFailure("SERVER ERROR")
          );
        }
      })
      .catch((error) => {
        dispatch(
          RESPONSE_ACTIONS.responseDropdownRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
