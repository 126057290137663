import {
  ACTIVATION_UPSERT_API,
  ACTIVATION_DETAIL_FETCH_API,
  ACTIVATION_FETCH_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as ACTIVATION_ACTIONS from "./actions";

export const fetchActivationList = (formData) => {
  const api = new APICore();

  const getApi = () => {
    return api.storeAPI(ACTIVATION_FETCH_API, formData);
  };

  return (dispatch) => {
    dispatch(ACTIVATION_ACTIONS.activationFetchRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            ACTIVATION_ACTIONS.activationFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          ACTIVATION_ACTIONS.activationFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchActivationDetailByIdentifier = (id, type = "test-drive") => {
  const api = new APICore();

  const getApi = () => {
    return api.fetchAPI(`${ACTIVATION_DETAIL_FETCH_API}/${id}`);
  };

  return (dispatch) => {
    dispatch(ACTIVATION_ACTIONS.activationDetailFetchRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            ACTIVATION_ACTIONS.activationDetailFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          ACTIVATION_ACTIONS.activationDetailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertActivation = (formData, action = "update") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "update": {
        return api.storeAPI(
          `${ACTIVATION_UPSERT_API}?_method=PUT`,
          formData
        );
      }

      case "delete": {
        return api.deleteAPI(`${ACTIVATION_UPSERT_API}/${formData}`);
      }

      default:
        return;
    }
  };

  return (dispatch) => {
    dispatch(ACTIVATION_ACTIONS.activationUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            ACTIVATION_ACTIONS.activationUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          ACTIVATION_ACTIONS.activationUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
