import {
  EXCHANGE_UPSERT_API,
  EXCHANGE_DETAIL_FETCH_API,
  EXCHANGE_FETCH_API,
} from "../../constants";
import { APICore } from "../../utils";
import * as EXCHANGE_ACTIONS from "./actions";

export const fetchExchangeList = (formData) => {
  const api = new APICore();

  const getApi = () => {
    return api.storeAPI(EXCHANGE_FETCH_API, formData);
  };

  return (dispatch) => {
    dispatch(EXCHANGE_ACTIONS.exchangeFetchRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            EXCHANGE_ACTIONS.exchangeFetchRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          EXCHANGE_ACTIONS.exchangeFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const fetchExchangeDetailByIdentifier = (id, type = "test-drive") => {
  const api = new APICore();

  const getApi = () => {
    return api.fetchAPI(`${EXCHANGE_DETAIL_FETCH_API}/${id}`);
  };

  return (dispatch) => {
    dispatch(EXCHANGE_ACTIONS.exchangeDetailFetchRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          if (type === "test-drive") {
            dispatch(
              EXCHANGE_ACTIONS.exchangeDetailFetchRequestSuccess(
                response?.data?.data
              )
            );
          } else {
            let newResponse = { ...response?.data?.data };

            dispatch(
              EXCHANGE_ACTIONS.exchangeDetailFetchRequestSuccess(newResponse)
            );
            return newResponse;
          }

          return response;
        }
      })
      .catch((error) => {
        dispatch(
          EXCHANGE_ACTIONS.exchangeDetailFetchRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};

export const upsertExchange = (formData, action = "update") => {
  const api = new APICore();

  const getApi = () => {
    switch (action) {
      case "update": {
        return api.storeAPI(
          `${EXCHANGE_UPSERT_API}/remarks?_method=PUT`,
          formData
        );
      }

      case "delete": {
        return api.deleteAPI(`${EXCHANGE_UPSERT_API}/${formData}`);
      }

      default:
        return;
    }
  };

  return (dispatch) => {
    dispatch(EXCHANGE_ACTIONS.exchangeUpsertRequest());

    return getApi()
      .then((response) => {
        if (response?.status === 200) {
          dispatch(
            EXCHANGE_ACTIONS.exchangeUpsertRequestSuccess(response?.data?.data)
          );
          return response;
        }
      })
      .catch((error) => {
        dispatch(
          EXCHANGE_ACTIONS.exchangeUpsertRequestFailure(
            error?.response ? error?.response?.data : "SERVER ERROR"
          )
        );
      });
  };
};
